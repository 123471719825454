import React, { useContext } from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import _isUndefined from "lodash/isUndefined";
import _find from "lodash/find";
import ActionButtons from "./ActionButtons";
import { CreateProjectWizardContext } from "./CreateProjectWizardContext";
import I18n from "../../../../utils/i18n";

const EVENT_TYPES = [
    { label: I18n.t("negotiations.general_settings.types.sealed"), value: "SealedNegotiation" },
    { label: I18n.t("negotiations.general_settings.types.english"), value: "EnglishNegotiation" },
    { label: I18n.t("negotiations.general_settings.types.japanese"), value: "JapaneseNegotiation" },
    {
        label: I18n.t("negotiations.general_settings.types.legal/sealed"),
        value: "Legal::SealedNegotiation",
    },
    {
        label: I18n.t("negotiations.general_settings.types.legal/english"),
        value: "Legal::EnglishNegotiation",
    },
    {
        label: I18n.t("negotiations.general_settings.types.legal/deal_room"),
        value: "Legal::DealRoomNegotiation",
    },
];

export const CellInput = ({
    row: {
        original: { id, project_size },
    },
}) => {
    const { selectedEvent, setSelectedEvent } = useContext(CreateProjectWizardContext);

    return !_isUndefined(project_size) && project_size > 1 ? (
        <span className="arrow"> &nbsp; </span>
    ) : (
        <Form.Check
            custom
            type="radio"
            id={`event_${id}`}
            checked={id === selectedEvent}
            onChange={() => setSelectedEvent(id)}
        />
    );
};

CellInput.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            project_size: PropTypes.number,
            id: PropTypes.number.isRequired,
        }),
    }),
};

export const Type = ({
    row: {
        original: { type },
    },
}) => _find(EVENT_TYPES, ["value", type]).label;

Type.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            type: PropTypes.string.isRequired,
        }),
    }),
};

export const TemplateCellInput = ({
    row: {
        original: { id },
    },
    selectedEvent,
    setSelectedEvent,
}) => (
    <Form.Check
        custom
        type="radio"
        id={`template_${id}`}
        checked={id === selectedEvent}
        onChange={() => setSelectedEvent(id)}
    />
);

TemplateCellInput.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }),
    }),
    selectedEvent: PropTypes.number.isRequired,
    setSelectedEvent: PropTypes.func.isRequired,
};

export const ActionButtonsCell = ({
    row: {
        original: { id, favorite },
    },
    showFavoriteButtons = false,
    currentWhitelabel,
}) => (
    <>
        <div className="action-buttons-container">
            <ActionButtons
                eventId={id}
                isFavorite={favorite}
                showFavoriteButtons={showFavoriteButtons}
                currentWhitelabel={currentWhitelabel}
            />
        </div>
    </>
);

ActionButtonsCell.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            id: PropTypes.number.isRequired,
            favorite: PropTypes.bool,
        }),
    }),
    showFavoriteButtons: PropTypes.bool,
    currentWhitelabel: PropTypes.shape({
        hosts: PropTypes.shape({
            bid: PropTypes.string.isRequired,
        }),
    }),
};
