import React, { useRef } from "react";
import { getIn, useField, useFormikContext } from "formik";
import classNames from "classnames";
import { Row, Col, Badge } from "react-bootstrap";
import sanitizeHtml from "sanitize-html";
import PropTypes from "prop-types";
import TinymceEditor from "./TinymceEditor";

const TinymceField = ({ placeholder = "", initTinyMceAttributes, name, maxFieldCount }) => {
    const editorRef = useRef(null);

    const [field] = useField(name);

    const sanitizeValue = sanitizeHtml(field.value, {
        allowedTags: [],
        allowedAttributes: [],
    }).length;

    const { setFieldValue, errors } = useFormikContext();

    return (
        <div className={classNames({ "is-invalid-block": getIn(errors, name), "is-invalid": getIn(errors, name) })}>
            <TinymceEditor
                onInit={(evt, editor) => (editorRef.current = editor)}
                value={field.value}
                onEditorChange={(value) => setFieldValue(name, value)}
                init={{
                    skin: "oxide",
                    skin_url: "/assets/packs/tinymce/skins/ui/oxide",
                    content_css: "/assets/packs/tinymce/skins/content/default/content.min.css",
                    min_height: 125,
                    menubar: false,
                    elementpath: false,
                    force_br_newlines: true,
                    convert_newlines_to_brs: true,
                    indent: false,
                    newline_behavior: "linebreak",
                    remove_trailing_brs: false,
                    force_p_newlines: false,
                    remove_linebreaks: false,
                    convert_unsafe_embeds: true,
                    branding: false,
                    contextmenu: false,
                    content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:12px }",
                    placeholder: placeholder,
                    toolbar_mode: "floating",
                    ui_mode: "split",
                    plugins: "lists link table",
                    font_family_formats:
                        "Andale Mono='andale mono', monospace;Arial=arial, helvetica, sans-serif;" +
                        "Arial Black='arial black', sans-serif;Book Antiqua='book antiqua', palatino, serif;" +
                        "Comic Sans MS='comic sans ms', sans-serif;Courier New='courier new', courier, monospace;" +
                        "Georgia=georgia, palatino, serif;Helvetica=helvetica, arial, sans-serif;Impact=impact, sans-serif;" +
                        "Symbol=symbol;Tahoma=tahoma, arial, helvetica, sans-serif;Terminal=terminal, monaco, monospace;" +
                        "Times New Roman='times new roman', times, serif;Trebuchet MS='trebuchet ms', geneva, sans-serif;" +
                        "Verdana=verdana, geneva, sans-serif;",
                    toolbar:
                        "bold italic underline | fontfamily fontsize | bullist numlist |" +
                        "strikethrough alignleft aligncenter alignright alignjustify | " +
                        "removeformat subscript superscript | link unlink |" +
                        "table tableinsertdialog tablecellprops tableprops advtablerownumbering |" +
                        "forecolor backcolor",
                    ...initTinyMceAttributes,
                }}
            />
            {maxFieldCount && (
                <Row>
                    <Col className="text-right">
                        <Badge>
                            {sanitizeValue}/{maxFieldCount}
                        </Badge>
                    </Col>
                </Row>
            )}
        </div>
    );
};

TinymceField.propTypes = {
    placeholder: PropTypes.string,
    initTinyMceAttributes: PropTypes.object,
    name: PropTypes.string.isRequired,
    maxFieldCount: PropTypes.number,
};

export default TinymceField;
